const termsOfService = `
<p>Welcome to e-citizen.ng or e-citizen. e-citizen is provided by Biosec Solutions Limited. Please read these Terms of Use (“Terms”) and Privacy Policy carefully because they are a binding agreement between You and Biosec Solutions Limited, (“Biosec” or “We”). These Terms clarify expectations and to set out our respective rights and obligations relating to your use of the E-citizen. </p>
<p>These Terms govern your use of e-citizen linked to these Terms. In these Terms, e-citizen.ng refers to all of e-citizens platforms owned and controlled by Biosec and the services offered on them. You automatically agree to these Terms and to our Privacy Policy simply by using or logging into e-citizen. 
</p><p>These Terms may change from time to time. We will post any changes to our Terms on the e-citizen and we shall notify you of updates by revising the “Last Updated” date listed on the top of the page. If the changes are material, we will endeavour to provide a more prominent notice (such as email notification). 
</p>
<p>Please note that we may now or in the future offer different services. Your use of our products or services are provided by Biosec pursuant to a separate manually or digitally-executed agreement. Those additional terms become part of your agreement with us if you use the services or log into e-citizen. </p>
<h5>Ability to Accept Terms </h5>
<p>You affirm that you are 18 years and above, or possess legal parental or guardian consent, and are fully able and competent to enter the terms, conditions, obligations, affirmations, representations, and warranties set forth in these Terms, and to abide by and comply with these Terms. 
</p>
<h5>Your Account </h5>
<p>You may create an account and specify a password in order to use certain services or features on e-citizen. You agree that all information you provide to use will be complete, true, and correct. If your information changes at any time, you agree that will be updated to reflect those changes.</p>

<p>You may not share your account or password with anyone else. Please keep your password confidential, and you will be solely responsible for securing your username and password and for all use of e-citizen using your email address or password. If you believe that your account has been compromised at any time, please notify us on info@e-citizen.ng. You will be solely liable for any use of e-citizen under your account and password. You agree to notify us promptly if you become aware of any unauthorized use or disclosure of your password. We reserve the right to refuse use of or revoke use of any username in our discretion.</p>
<h5>Modifications </h5>
<p>We reserve the right to modify e-citizen at any time, with or without notice to you. For example, we may add or remove functionality or features, and we may suspend or stop a particular feature altogether. We also reserve the right to charge a fee for any of our features at any time. If you don’t like any changes, you can stop using e-citizen at any time.</p>
<h5>Termination </h5>
<p>We may at any time cease to continue operating part or all or selectively disable certain aspects of e-citizen. You may cease using e-citizen at any time, provided, that you acknowledge and agree that any content posted by you prior to such time will continue to be available on and through e-citizen. We may terminate your use of e-citizen if you violate these Terms with or without prior notice to you. </p>
<h5>Your Use of the e-citizen </h5>
<p>Your use of e-citizen will comply with all applicable laws. You will not attempt to circumvent or violate any security feature of e-citizen, including accessing any e-citizen features, interactive areas, information or profiles for which you do not have permission or other content or information not intended for you. As part of your use of e-citizen, you agree that you will not: 
</p>
<ul>
<li>interfere with, or attempt to interfere with, the normal operations of e-citizen or any other user’s use of e-citizen, including by overloading, flooding, spamming or crashing e-citizen or its underlying systems or by altering any profile, reviews or other information provided by any user; 
</li>
<li>post, share, link to or submit any Content or material that is libelous, defamatory, invasive of privacy or publicity rights, vulgar, profane, indecent, obscene, sexually explicit or exploitative; 
</li>
<li>use e-citizen to harass, bully, threaten, humiliate, stalk or otherwise intimidate any other user or any third party; • post, share, link to or submit any content or material that constitutes hate speech, promotes violence against any group or person or is otherwise objectionable; 
</li>
<li>post, share, link to or submit any content or material that is fraudulent, false, misleading or deceptive; 
</li>
<li>post, share or disclose any personally identifiable information regarding any third party; 
</li>
<li>impersonate or falsely suggest or claim an affiliation with any other person or entity; 
</li>
<li>seek to monetize e-citizen, including by providing reviews or endorsements for payment or other consideration from any third party; 
</li>
<li>collect, store or analyze information about other users, except as authorized by such User; 
</li>
<li> expose us to any civil or criminal liability; o</li>
<li>violate any applicable law or encourage conduct that would constitute a criminal offense. 
</li>
</ul>
<p>If we (in our sole discretion) determine that you have acted inappropriately, we reserve the right to take down Content, terminate your account, prohibit you from using e-citizen, and take any other appropriate legal actions. 
Using e-citizen does not give you ownership of any intellectual property rights to the Content you access. You may not use Content from e-citizen unless you obtain permission from us or its owner, or unless you are otherwise permitted by law. 
When you use e-citizen or send communications to us through e-citizen, you are communicating with us electronically. You consent to receive electronically any communications related to your use of e-citizen. We may communicate with you by email or by posting notices on e-citizen. You agree that all agreements, notices, disclosures and other communications that are provided to you electronically satisfy any legal requirement that such communications be in writing. All notices from us intended for receipt by you shall be deemed delivered and effective when sent to the email address you provide to us. Please note that by submitting Content, creating a user account or otherwise providing us with your email address, postal address or phone number, you are agreeing that we or our agents may contact you at that address or number in a manner consistent with our Privacy Policy. </p>
<h5>Online Payment </h5>
<p>Our Service permits online payment of necessary fees using third-party online payment services that support online payments. You will be liable to the payment information you provide to these online payment services and we will not be held accountable for any invalid information provided nor will we be held responsible for the initialization, implementation, progression, nor completion of any possible refund process that arises during your use of our Solutions and Service.</p>
<p>You are encouraged to ensure the correctness and accuracy of the search criteria. You will not be refunded if the search information is incorrect or does not exist. </p>
<h5>Data Subject’s Access to Registered Information </h5>
<p>Our service is consent driven where applicable. Consent of the data subject is required before information is divulged to a requesting user. You will not be refunded where a data subject does not have access to the registered phone number or email address in the associated database. or if the data subject denies consent. </p>
<h5>Accuracy and Integrity of Data </h5>
<p>e-citizen is an aggregator of data and only returns results reported by data sources of obligor institutions. e-citizen is not responsible for the accuracy or integrity of any data returned, nor does e-citizen provide such guarantee. Any inquiries on accuracy or integrity of data should be referred to the obligor institution.  </p>

<h5>Intellectual Property </h5>
<p>As between you and Biosec, e-citizen, including all content, graphics, audio, video, pictures, trademarks, Platform marks, logos and other material on e-citizen, and its underlying software (the “Software”), algorithms, databases, look and feel and arrangement, are the intellectual property of Biosec, subject to copyright and other intellectual property protections. e-citizen’s mark and logo are the trademarks of Biosec. All rights in and to e-citizen and the Software not expressly granted herein are reserved by us. We grant you and you accept a non-exclusive, non-transferable, revocable license to use the Software solely for your own use of e-citizen and in accordance with these Terms. 
</p>
<p>If you believe any Content on e-citizen infringes your copyrights, you may request the removal of the Content from e-citizen (or disable access to that Content) by contacting us at dpo@biosec.com.ng. We are committed to using commercially reasonable efforts to investigate each claim. 
</p>
<h5>Social Networks </h5>
<p>e-citizen may include features that operate in conjunction with certain third-party social networking websites that you visit such as Facebook, Instagram, YouTube, Vimeo, and Twitter. While your use of such features is governed by these Terms, your access and use of third party social networking sites is governed by the terms of service and other agreements posted on these sites. You are responsible for ensuring that your use of those sites complies with any applicable terms of service or other agreements. 
</p>
<h5>Warranty Disclaimer </h5>
<p>E-CITIZEN IS PROVIDED ON AN "AS IS" AND “AS AVAILABLE” BASIS. WE HEREBY DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESSED OR IMPLIED, WITH RESPECT TO E-CITIZEN OR ITS CONTENT, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSES, AND NON-INFRINGEMENT OF ANY THIRD PARTIES' INTELLECTUAL PROPERTY RIGHTS. BIOSEC MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY, ROBUSTNESS, SECURITY OR COMPLETENESS OF E-CITIZEN OR ITS CONTENT, OR OTHERWISE. WE MAKE NO WARRANTIES OR GUARANTEES REGARDING THE AVAILABILITY OR RELIABILITY OF E-CITIZEN OR THAT E-CITIZEN WILL BE ACCESSIBLE AT ANY SPECIFIC TIME. ALL USE OF E-CITIZEN IS AT YOUR OWN RISK. IF YOU ARE DISSATISFIED WITH E-CITIZEN, YOUR SOLE REMEDY IS TO DISCONTINUE USE AND OBTAIN A REFUND FOR ADVANCE PAYMENTS, IF ANY, AS SET FORTH IN THESE TERMS. </p>

<h5>Limitation of Liability </h5>
<p>EXCEPT WHERE PROHIBITED, BIOSEC SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES ARISING FROM YOUR USE OF E-CITIZEN OR ANY THIRD PARTY’S USE OF E-CITIZEN. THESE EXCLUSIONS INCLUDE, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS, LOST DATA, COMPUTER FAILURE, OR THE VIOLATION OF YOUR RIGHTS BY ANY THIRD PARTY, EVEN IF A PARTY HAD BEEN ADVISED OF THE POSSIBILITY THEREOF AND REGARDLESS OF THE LEGAL OR EQUITABLE THEORY UPON WHICH THE CLAIM IS BASED. IN NO EVENT SHALL BIOSEC’S AGGREGATE LIABILITY TO ANY INDIVIDUAL USER IN CONNECTION WITH USE OF E-CITIZEN EXCEED THE TOTAL AMOUNT THAT YOU PAID TO US FOR USE OF E-CITIZEN, WHETHER SUCH LIABILITY IS BASED IN CONTRACT, TORT OR OTHERWISE. </p>

<h5>Indemnification </h5>
<p>You hereby agree to indemnify, defend and hold Biosec and its affiliates and each of their owners, members, directors, officers, managers, employees, independent contractors, subcontractors, agents and representative ("Indemnified Parties") harmless from any claim, cause of action, proceeding, liability, taxes, damages, loss, attorney fees, cost and expenses arising from or related to (i) your breach of these Terms and/or (ii) your use of e-citizen, including any Content you may submit or post on e-citizen. </p>
<h5>Additional Details </h5>
<p>These terms and your use of e-citizen are governed by the laws of the Federal Republic of Nigeria, without giving effect to its conflict of law provisions. You expressly agree that the exclusive jurisdiction for any claim or dispute under these Terms and or your use of e-citizen resides in the courts located in the Federal Republic of Nigeria, and you further expressly agree to submit to the personal jurisdiction of such courts for the purpose of litigating any such claim or action. If it turns out that a particular provision in these Terms is not enforceable, that will not affect any other provision. </p>
<p>You may not assign your rights hereunder. We can assign our rights hereunder at any time without restriction. 
</p>
<p>We may modify these Terms at any time so be sure to check back regularly. By continuing to use or log in to e-citizen after these Terms have changed, you indicate your agreement to the revised Terms. If you do not agree to the changes, you should stop using or logging in to e-citizen. 
</p>
<p>e-citizen may contain links to third-party sites. Please note that we do not control nor endorse those sites, or any goods or services sold on those sites. </p>
<p>Please note that we are committed to ensuring the security and protection of all personal information that we hold and safeguarding the rights of data subjects to data privacy and protection. We are compliant with the applicable regulatory requirements regarding data privacy and protection, and our internal data protection processes continue to undergo review to ensure these are fully optimized in accordance with applicable data protection laws and changes in regulatory requirements. </p>
<p>Please read our Privacy Policy for information on how we process your personal data. </p>
`;

export default termsOfService;
