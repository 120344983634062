const privacyPolicy = `
<h6>Overview </h6>
<p>In order to provide verification services to e-citizen™ users, Biosec Solutions Limited (“Biosec” or “the Company”) collects and/or processes  certain  types  of  information  (such  as  name, telephone  numbers,  address   etc.)  of  individuals  that  makes  them  easily  identifiable.  These individuals include users  signing  up to e-citizen™ as well as individuals  whose personal  details are verified (with their consent) by e-citizen™ users, jointly and/or severally (“Data Subjects”). </p>

<p>This Privacy Policy applies to all forms of systems, operations, and processes within the e- citizenTM environment that involve the collection, storage, use, transmission, and disposal of Personal Data.</p>
<h6>Category of Data Collected and Processed</h6>
<p>We only collect and use your Personal data if we have obtained your prior consent or have a lawful and legitimate interest to do so. You are at liberty to withdraw your consent at any time by contacting the Data Protection Officer at info@biosec.com.ng. The following are data collected and processed for e-citizenTM use:</p>
<ul>
  <li>Identification (name, NIN) and Communication data (telephone, address, e-mail, IP address)</li>
  <li>User history on e-citizenTM</li>
  <li>Billing and payments records</li>
  <li>Disclosed information (from third parties)</li>
</ul>
<p>The following are methods adopted in the collection and storage of personal data for e- citizenTM:</p>
<ul>
  <li>Data collection forms on the web or mobile apps</li>
  <li>Cookies</li>
  <li>System logs</li>
  <li>Data retrieval from third parties (via API)</li>
</ul>
<h6>Purpose of Data Collected and Processed</h6>
<p>e-citizenTM collects Personal Data for only for the purposes identified here and for which Consent has been obtained.
In line with the provisions of the NDPA, processing of Personal Data for e-citizenTM shall be lawful if at least one of the following applies:</p>
<ul>
  <li>the Data Subject has given Consent to the processing of his/her Personal Data for one or more specific purposes,</li>
  <li>the processing is necessary for the performance of an agreement to which the Data Subject is party or in order to take steps at the request of the Data Subject prior to entering into an agreement,</li>
  <li>processing is necessary for compliance with a legal obligation to which the Company is subject,</li>
  <li>processing is necessary in order to protect the vital interests of the Data Subject or of another natural person,</li>
  <li>processing is necessary for the performance of a task carried out in the public interest or in exercise of official public mandate vested in the Company, and</li>
  <li>processing is necessary for the legitimate interests pursued by the Data Controller or Data Processor, or by a third party to whom the data is disclosed, as long as this does not override the fundamental rights, freedoms, and interests of the Data Subject, is not incompatible with the other lawful basis of processing, and the Data Subject has a reasonable expectation that the data will be processed in the manner envisaged.</li>
</ul>
<h6>Purposes for which we collect and process Personal Data include:</h6>
<ul>
  <li>to provide our services to users including verification services,</li>
  <li>to comply with legal and regulatory requirements,</li>
  <li>to contact our users and effectively respond to support requests,</li>
  <li>to provide user notifications and other operational communications,</li>
  <li>for logging and record-keeping purposes.</li>
</ul>
<h6>Personal Data Retention</h6>
<p>All personal information collected from e-citizenTM users shall be retained, stored, and destroyed in line with relevant Legislative and Regulatory Guidelines. For all Personal Data and records obtained, used, and stored, the Company shall perform periodical reviews of the data retained to confirm the accuracy, purpose, validity, and requirement to retain.</p>
<p>To the extent permitted by applicable laws and without prejudice to the Company’s Retention Policy, the length of storage of Personal Data collected from e-citizenTM users shall, amongst other things, be determined by:</p>
<ul>
  <li>whether it is still needed for the purpose for which it was obtained; or</li>
  <li>whether the transaction or relationship has statutory implication or a required retention period; or</li>
  <li>an express request for deletion by the Data Subject; except where such Data Subject is under an investigation or under a subsisting contract which may require further processing or where the data relates to criminal records; or</li>
  <li>whether the Company has another lawful basis for retaining that information beyond the period for which it is necessary to serve the original purpose.</li>
</ul>
<p>Notwithstanding the foregoing and pursuant to the NDPA, the Company shall be entitled to retain and process Personal Data for archiving, scientific research, historical research, or statistical purposes for public interest.</p>

<p>The Company would forthwith delete Personal Data in the Company’s possession where such Personal Data is no longer required for e-citizenTM access or in line with the Company’s Retention Policy, provided no law or regulation being in force requires the Company to retain such Personal Data.</p>
<p>Personal  Data from  the  NIMC  system retrieved  for  transmission  as  part  of  an e-citizen™ verification process shall be retained for a maximum of 24 hours while awaiting the Data Subject’s consent, and a maximum of a further 24 hours after consent has been granted, during which time the e-citizen™ user who requested the data will be able to access </p>

<p>The  Company would  forthwith  delete Personal Data  retrieved as  part  of  an e-citizen™ verification process once 24 hours have elapsed from when consent was granted by the Data Subject.  Vehicle data shall be retained for a maximum of 7 days. Financial credit data from credit bureaus shall be retained for a maximum of 7 days</p>


<h6>Data Security</h6>
<p>The  Company considers  Personal Data confidential  and  as  such  must  be  adequately protected from unauthorized use and/or disclosure.  The  Company has  established adequate controls in  order  to  protect the  integrity and confidentiality of Personal Data, and to prevent personal data from being accidentally or deliberately compromised.</p><p> All data processed by e-citizen™ is encrypted both in storage and in transit. However, it must be  noted  that  no  security  measures  are  completely foolproof. The  risk  of  a  breach, although diminished, is still present.<p>
<h6>Data Breach Management Procedure</h6>
<p>A data breach procedure is established and maintained in order to deal with incidents concerning Personal  Data  or  privacy  practices  leading  to  the  accidental  or  unlawful  destruction,  loss, alteration,   unauthorized  disclosure  of,  or  access  to,  Personal  Data transmitted,  stored   or otherwise processed</p>
<h6>Consent</h6>
<p>Consent is required for the processing of Personal Data on e-citizenTM. The Company shall obtain the requisite consent of Data Subjects at the time of collection or processing of Personal Data. In this regard, the Company will ensure:</p>
<ul>
  <li>that the specific purpose of collection is made known to the Data Subject and the Consent is requested in a clear and plain language,</li>
  <li>that the Consent is freely given by the Data Subject and obtained without fraud, coercion or undue influence,</li>
  <li>that the Consent is sufficiently distinct from other matters to which the Data Subject has agreed,</li>
  <li>that the Consent is explicitly provided in an affirmative manner,</li>
  <li>that Consent is obtained for each purpose of Personal Data collection and processing;</li>
  <li>and that it is clearly communicated to and understood by Data Subjects that they can update, manage, or withdraw their Consent at any time.</li>
</ul>
<p>The Consents of minors (under the age of 18) will always be protected and obtained from minor’s representatives in accordance with applicable regulatory requirements.</p>
<h6>Data Subject Rights</h6>
<p>All individuals who are the subject of Personal Data held by e-citizenTM are entitled to the following rights:</p>
<ul>
  <li>Right to request for and access their Personal Data collected and stored. Where data is held electronically in a structured form, such as in a Database, the Data Subject has a right to receive that data in a common electronic format.</li>
  <li>Right to information on their personal data collected and stored.</li>
  <li>Right to objection or request for restriction.</li>
  <li>Right to object to automated decision making.</li>
  <li>Right to request rectification and modification of their data which the Company keeps.</li>
  <li>Right to request for deletion of their data, except as restricted by law or the Company’s statutory obligations.</li>
  <li>Right to request the movement of data from the Company to a Third Party; this is the right to the portability of data;</li>
  <li>and Right to object to, and to request that the Company restricts the processing of their information except as required by law or the Company’s statutory obligations.</li>
  <li>Data Subjects can exercise any of their rights by completing the Company’s Subject Access Request (SAR) Form and submitting to the Company via info@biosec.com.ng</li>
</ul>
<h6>Transfer of Personal data to Foreign Country</h6>
<p>
Transfer of Personal Data out of Nigeria would be in accordance with the provisions of the NDPA. The Company will record the basis for any transfer and take all necessary steps to ensure that the Personal Data is transmitted in a safe and secure manner. Details of the protection given to your information when it is transferred outside Nigeria shall be provided to you upon request.</p>
<h6>Changes to the Policy </h6>
<p>The Company reserves the right to change, amend or alter this Policy at any point in time. If we amend this Policy, we will provide you with the updated version.  </p>
<h6>Glossary </h6>
<h6>Consent</h6>
<p>means any freely given, specific, informed, and unambiguous indication of the Data Subject's wishes by which he or she, through a statement or a clear affirmative action, signifies agreement to the processing of Personal Data relating to him or her. </p>
<h6>Database</h6>
<p>means a collection of data organized in a manner that allows access, retrieval, deletion, and processing of that data; it includes but not limited to structured, unstructured, cached and file system type Databases.</p>
<h6>Data Processor</h6>
<p>means a person or organization that processes Personal Data on behalf and on instructions of the Company.  </p>
<h6>Data Subject</h6>
<p>means any person who can be identified, directly or indirectly, by reference to an identification number or to one or more factors specific to his physical, physiological, mental, economic, cultural, or social identity.</p>
<h6>NDPA</h6>
<p>means the Nigeria Data Protection Act, 2023. </p>
<h6>Personal Data</h6>
<p>means any information relating to an identified or identifiable natural person (‘Data Subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person; It can be anything from a name, address, a photo, an email address, bank details, posts on social networking websites, medical information, and other unique identifier such as but not limited to MAC address, IP address, IMEI number, IMSI number, SIM, Personal Identifiable Information (PII) and others. </p>

`;
export default privacyPolicy;
